import React, { useEffect, useState, useContext } from "react";
import ReactEcharts from "echarts-for-react";


import { Context } from "../contexts/Context";

import { arrayOfObjects } from "../helpers/getMaxValue";
import { numberWithCommas } from "../helpers/valueFormat";

function NodeChart(props) {
  // Props Destructure
  const { data: { incoming, outgoing, type }, fetchChildren = () => {} } = props

  // Context
  const { updateIsLoadingState } = useContext(Context);

  // Variables
  const defaultLegendSelection = {
    incoming: type === 'Whitelisting' ? true : false,
    outgoing: type === 'Whitelisting' ? false : true,
  }
  const maxIncomingValue = arrayOfObjects(incoming, "children", "value");
  const maxOutgoingValue = arrayOfObjects(outgoing, "children", "value");

  // States
  const [propsData] = useState({ incoming, outgoing })
  const [incomingChartData, setIncomingChartData] = useState([])
  const [outgoingChartData, setOutgoingChartData] = useState([])

  // Functions

  /** Get chart's max amount/balance value
   * @param  {} array Chart node data items
   */
  const getChartDataMaxValue = (array) => {
    let maxValue = 0
    
    const getMaxValueRecursively = (array) => {
      if (array.length) {
        array.map(item => {
          if (item.value.value) {
            if (item.value.value > maxValue) maxValue = item.value.value
          }
          getMaxValueRecursively(item.children)
        })
      }
    }

    getMaxValueRecursively(array)

    return maxValue
  }

  /** Update node symbol size and label size
   * @param  {} chartData Current specific direction chart data
   * @param  {} maxChartValue Max node amount value across chart to put ratio on symbol size and label style
   */
  const updateNodeStyles = (chartData, maxChartValue) => {

    const updateChartSymbolSizeRecursively = (chartData) => {

      return chartData.map(item => {
        const cappedAmountForGraph = (item.value.value / maxChartValue) * 45 + 5

        if (item.children.length) {
          return { 
            ...item, 
            children: updateChartSymbolSizeRecursively(item.children)
          }
        } else if (!item.children.length){
          return { 
            ...item, 
            symbolSize: cappedAmountForGraph, 
            label: { 
              ...item.label, 
              distance: -(cappedAmountForGraph / 2),
              rich: {
                ...item.label.rich,
                amount: {
                  ...item.label.rich.amount,
                  padding: item.value.direction === 'incoming' ? 
                    [0, cappedAmountForGraph / 2 + 5, -5, 0] : 
                    [0, 0, -5, cappedAmountForGraph / 2 + 5],
                },
                hash: {
                  ...item.label.rich.amount,
                  padding: item.value.direction === 'incoming' ? 
                    [0, cappedAmountForGraph / 2 + 5, 0, 0] : 
                    [0, 0, 0, cappedAmountForGraph / 2 + 5],
                }
              }
            }  
          }
        }
      })
    }
    
    const data = updateChartSymbolSizeRecursively(chartData)

    return data
  }

  /** Add children to chart node
   * @param  {} hash Hash of node clicked
   * @param  {} data Current chart data
   * @param  {} params Parameter use to fetch children data
   * @param  {} echart Echart/Chart instance
   */
  const addChildrenData = (hash, data, params, echart) => {
    return fetchChildren(params).then(fetchedChildrenData => {

      console.log(fetchedChildrenData);

      if (fetchedChildrenData.toString().includes('Error')){
        alert(`No further information`)
        // console.log(propsData.outgoing)
        // console.log(type, direction);
        // //echart.hideLoading()
        return
      }

      if (fetchedChildrenData !== 'error' && fetchedChildrenData[0].hash) {

        const formattedFetchedData = formatChartData(fetchedChildrenData) 
        
        const updateChartDataRecursively = (hash, data) => {

          return data.map(item => {
            if (item.value.hash === hash && !item.children.length) {
              return { ...item, children: formattedFetchedData }
            } else if (item.hash !== hash && item.children.length) {
              return { ...item, children: updateChartDataRecursively(hash, item.children) }
            } else {
              return item
            }
          })
        }

        // Added children data
        const updatedChartData = updateChartDataRecursively(hash, data)

        // Get max value from chart
        const maxChartValue = getChartDataMaxValue(updatedChartData)

        // Updated chart node style data based on max value ratio
        const updatedChartNodeStyleData = updateNodeStyles(updatedChartData, maxChartValue)

        // Updated echart instance series data
        const updatedSeriesData = echart.getOption().series.map(seriesItem => {
          if (seriesItem.name === params.direction) {
            return { ...seriesItem, data: updatedChartNodeStyleData }
          } else {
            return seriesItem
          }
        })

        // Return update chart data
        return updatedSeriesData
      
      } else {
        // Prompt data fetch error. (Current workaround alert ui will be implemented on v2)
        alert('Error fetching node data.')

        // Return current data
        return echart.getOption()
      }

    })
  }

  /** Format fetched children data for chart
   * @param  {} content Children data
   */
  const formatChartData = (content) => {
    return content.map(
      ({
        id,
        type,
        asset,
        value,
        balanceUSD,
        amountUSD,
        hash,
        riskScore,
        flag,
        color,
        children,
        lastActivity,
        timestamp,
        direction
      }) => {

        // Get max value from raw children data
        const maxValue = direction === 'incoming' ? maxIncomingValue : maxOutgoingValue

        // Compute ratio of node value based on max value
        const cappedAmountForGraph = (value / maxValue) * 45 + 5;

        // Conditional value data
        const valueUSD = type === 'transaction' ? amountUSD : balanceUSD;

        return {
          name: id,
          value: {
            type,
            asset,
            amount: `${value} ${asset}`,
            value: value,
            valueUSD: valueUSD,
            hash,
            riskScore,
            flag,
            color,
            direction,
            lastActivity: type === 'transaction' ? timestamp : lastActivity
          },
          symbol: type === 'transaction' ? "square" : "circle",
          symbolSize: cappedAmountForGraph,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#777",
            color,
          },
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove',
            borderColor: 'none',
            padding: 0,
            formatter: ({ value: { type, asset, value, valueUSD, hash, color, riskScore, flag, lastActivity } }) => {
              return `
                <div style="background: #fff; border: 0; padding: 15px; margin: 0px; border-radius: 5px; border: 1px solid lightgrey; width: 550px;">
                  <p style="padding: 0px 0px 10px 5px; margin: 0px; color: grey; font-size: 14px; font-weight: 700; color: black;">${hash}</p>
                  <ul style="padding: 0px; margin: 0px; list-style: none; color: black;">
                    <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid lightgrey; whitespace: wrap;">
                      Type: ${type}
                    </li>
                    <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid lightgrey; whitespace: wrap;">
                      Asset: ${asset}
                    </li>
                    <li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid lightgrey; whitespace: wrap;">
                      ${type === 'transaction' ? `Amount ${asset}` : `Balance ${asset}`}: ${value}
                    </li>
                    <li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid lightgrey; whitespace: wrap;">
                      ${type === 'transaction' ? "Amount USD" : "Balance USD"}: ${type === 'transaction' ? 'N/A' : numberWithCommas(valueUSD)}
                    </li>
                    <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid ${color}; whitespace: wrap;">
                      Risk Score: ${riskScore}
                    </li>
                    <li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; border-left: 5px solid ${color}; whitespace: wrap;">
                      Flags: ${flag}
                    </li>
                    <li style="margin: -1px; padding: 5px; border-left: 5px solid lightgrey; whitespace: wrap;">
                    ${type === 'transaction' ? "Timestamp" : "Last Activity"}: ${lastActivity}
                    </li>
                  </ul>
                </div>
              `
            }
          },
          label: {
            position: direction === 'incoming' ? "left" : "right",
            verticalAlign: direction === 'incoming' ? "left" : "right",
            align: direction === 'incoming' ? "right" : "left",
            formatter: ({ value: { hash, amount } }) => {
              const shortenedHash = `${hash.slice(0, 5)}[...]${hash.slice(hash.length - 5)}`

              return (
                "{amount|" +
                amount +
                "}\n{hash|" +
                shortenedHash +
                "}"
              );
            },
            distance: -(cappedAmountForGraph / 2),
            rich: {
              amount: {
                padding: direction === 'incoming' ? 
                  [0, cappedAmountForGraph / 2 + 5, -5, 0] : 
                  [0, 0, -5, cappedAmountForGraph / 2 + 5],
                color: "#777",
                fontSize: 10,
                fontWeight: 600,
              },
              hash: {
                padding: direction === 'incoming' ? 
                  [0, cappedAmountForGraph / 2 + 5, 0, 0] : 
                  [0, 0, 0, cappedAmountForGraph / 2 + 5],
                color: "#777",
                fontSize: 10,
                fontWeight: 600,
              },
            },
          },
          children: children ? formatChartData(children) : [],
        };
      }
    );
  };

  // useEffect Hooks
  useEffect(() => {
    setIncomingChartData(formatChartData(propsData.incoming))
    setOutgoingChartData(formatChartData(propsData.outgoing))
  }, [])

  return (
    <ReactEcharts
      style={{
        height: "100vh",
        width: "100vw",
      }}
      option={{
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          enterable: true
        },
        legend: {
          top: '100px',
          left: '0px',
          orient: 'vertical',
          data: [{
              name: 'incoming',
              icon: 'none',
              color: '#777',
          },
          {
              name: 'outgoing',
              icon: 'none',
              color: '#777'
          }],
          formatter: function (name) {
            return name.charAt(0).toUpperCase() + name.slice(1)
          },
          selected: {
            incoming: type === 'Whitelisting' ? true : false,
            outgoing: type === 'Whitelisting' ? false : true,
          },
          textStyle: {
              color: '#777',
              fontWeight: 800,
              fontSize: 16,
              margin: 0,  
              padding: 0 
          },
          itemGap: 5,
        },
        series: [
          {
            type: "tree",
            name: 'incoming',
            roam: true,
            initialTreeDepth: -1,
            orient: 'RL',
            data: incomingChartData,
            zoom: .9,
            zoomToNodeRatio: 0.32 * 0.32,
            leaves: {
              label: {
                position: "left",
                verticalAlign: "middle",
                align: "right",
              },
            },
            right: '50%',
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
          {
            type: "tree",
            name: 'outgoing',
            roam: true,
            initialTreeDepth: -1,
            orient: 'LR',
            data: outgoingChartData,
            zoom: .9,
            zoomToNodeRatio: 0.32 * 0.32,
            leaves: {
              label: {
                position: "righ",
                verticalAlign: "middle",
                align: "left",
              },
            },
            left: '50%',
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      }}
      onEvents={{
        click: ({ value: { type, hash, direction, asset }, data, seriesType, event }, echart) => {
          if (data.children.length) return
          
          // Show loading feedback when fetching children data
          echart.showLoading('default', { color: '#777' })
          console.log(echart.getOption().series);         

          if (!echart.getOption().series.filter(({ name }) => name === direction )[0]) {
            alert(`No further information`)
            console.log(propsData.outgoing)
            console.log(type, direction);
            echart.hideLoading()
            return
          }

          // Get current chart data to be updated
          const currentDirectionData = echart.getOption().series.filter(({ name }) => name === direction )[0].data

          // Validate if event is from tree chart
          if (event.type === 'click' && seriesType === 'tree'){
            const params = { 
              [type]: hash,
              direction,
              asset,
              limit: 13,
              type
            }
            
            // Update chart series data
            addChildrenData(hash, currentDirectionData, params, echart).then(updatedSeriesData => {
              echart.setOption(
                { ...echart.getOption(), 
                  series: updatedSeriesData
                }
              )
              echart.hideLoading()
            })
          }
          
        },
        legendselectchanged: ({ type, name }, echart) => { 
          // Validate if event is from tree legend
          if (type === 'legendselectchanged') {

            // Show either incoming/outgoing chart on select
            echart.setOption(
              { ...echart.getOption(), 
                legend: [{ ...echart.getOption().legend, 
                  selected: {
                    incoming: name === 'outgoing' ? false : true,
                    outgoing: name === 'incoming' ? false : true
                  } 
                }]
              }
            )
          }
          
        }
      }}
      notMerge={true}
      lazyUpdate={true}
    />
  );
}

export default NodeChart;
